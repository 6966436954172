<template lang="pug">
	article#palestrantes
		section#palestrante
			figure.banner_evento
				img(:src="banner ? banner : require('@images/backgrounds/banner_convidados.jpg')").banner
			.wrapper
				router-link(:to="{name: 'evento'}").voltar
					img(:src="require('@images/icones/arrow.png')")
					span Voltar para o evento
				h2 Palestrantes
				ul
					li(v-for="palestrante, index in palestrantes")
						strong(v-if="palestrante.internacional") Internacional
						div.img
							img(:src="palestrante.thumb ? palestrante.thumb : require('@images/temp/imgeventos.png')")
						h3 {{palestrante.nome}}
						p {{palestrante.resumo}} 
						button(@click="toggleAside(index)") Veja mais
		aside#palestrante(:class="{'ativo' : aside}", v-if="palestrantes")
			div.container
				button(@click="toggleAside()") X
				h3 {{ palestrantes[palestranteAtivo].nome }}
				div.conteudo(v-html="palestrantes[palestranteAtivo].descricao")
</template>

<script>
export default {
	data() {
		return {
			palestrantes: null,
			aside: false,
			banner: null,
			palestranteAtivo: 0,
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$axios
				.get(`api/eventos/${to.params.slug}/convidados`)
				.then(response => {
					vm.palestrantes = response.data.convidados
					vm.banner = response.data.convidado_topo
				})
				.catch(() => {
					vm.$router.replace({ name: '404' })
				})
		})
	},
	methods: {
		toggleAside(index = null) {
			this.aside = !this.aside
			if(index != null)
				this.palestranteAtivo = index
			if(this.aside)
				document.querySelector('html').style.overflow = 'hidden'
			else
				document.querySelector('html').style.overflow = 'visible'
		}
	},
}
</script>

<style lang="stylus" scoped src="./Palestrantes.styl"></style>
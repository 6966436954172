import { render, staticRenderFns } from "./Palestrantes.vue?vue&type=template&id=172b07a0&scoped=true&lang=pug&"
import script from "./Palestrantes.vue?vue&type=script&lang=js&"
export * from "./Palestrantes.vue?vue&type=script&lang=js&"
import style0 from "./Palestrantes.styl?vue&type=style&index=0&id=172b07a0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "172b07a0",
  null
  
)

export default component.exports